import L from "leaflet";
import m from "mithril";
import tagl from "tagl";

/**
 * Workaround for marker images in parcel
 */

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

/** Navbar responsive + toggle on click */
document.addEventListener("DOMContentLoaded", () => {
  const $navbarBurgers = Array.prototype.slice.call(
    document.querySelectorAll(".navbar-burger"),
    0
  );
  if ($navbarBurgers.length > 0) {
    $navbarBurgers.forEach(el => {
      el.addEventListener("click", () => {  
        const target = el.dataset.target;
        const $target = document.getElementById(target);
        el.classList.toggle("is-active");
        $target.classList.toggle("is-active");
      });
    });
  }
});

/**
 * Enable smooth scrolling if possible
 */
if ("scroll" in window) {
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener("click", function(e) {
      e.preventDefault();
      const elementWhereToGo = document.querySelector(
        this.getAttribute("href")
      );
      const nav = document.querySelector(".navbar-brand");
      const top = elementWhereToGo.offsetTop - nav.clientHeight;
      const burger = document.querySelector(".navbar-burger");
      const target = burger.dataset.target;
      const $target = document.getElementById(target);

      burger.classList.remove("is-active");
      $target.classList.remove("is-active");
      window.scroll({ top: top, behavior: "smooth" });
    });
  });
}

if (document.querySelector("#map")) {
  const map = L.map("map", {
    dragging: false,
    zoomControl: false,
    zoom: false,
    scrollWheelZoom: false,
    closePopupOnClick: false
  }).setView([52.13851, 7.33936], 17);

  L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(map);

  L.marker([52.13831, 7.33928])
    .addTo(map)
    .bindPopup("Vermessungsbüro Eismann")
    .openPopup();
}

const camelToHyphen = s => s.replace(/([A-Z])/g, g => `-${g[0].toLowerCase()}`);

const tagl_hyperscript = tagl(function(tagName, classes, ...args) {
  let cls = classes.map(camelToHyphen).join(".");
  return m([tagName, cls].join(".").replace(".$", "#"), ...args);
});

const {
  div,
  article,
  button,
  input,
  h3,
  label,
  i,
  p,
  span,
  select,
  option,
  textarea
} = tagl_hyperscript;

let contact = document.querySelector("#contactform");

if (contact) {
  let visible = false;

  let anfrage = () => {
    return {
      name: "",
      email: "",
      message: "",
      acc: false
    };
  };

  let konkreteAnfrage = anfrage();

  let konkreteAntwort = null;

  const sendMessage = () => {
    console.log("Anfrage absenden", konkreteAnfrage);
    m.request({
      url: "/ns/mail.php",
      method: "POST",
      data: konkreteAnfrage
    }).then(response => {
      konkreteAntwort = response;
    });
  };

  const emailValid = email => {
    const regex = /^([a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]{1,64}@(?=.{1,255}$)([a-zA-Z0-9-]+[.]{1}[a-zA-Z0-9-]{2,})){1,320}$/;
    let m;
    let result = false;
    if ((m = regex.exec(email)) !== null) {
      m.forEach((match, groupIndex) => {
        result = true;
      });
    }

    //console.log(email, result ? " is valid" : "is not valid");
    return result;
  };

  const strValid = name => name && name.length > 0;

  class Form {
    constructor(vnode) {}
    view(vnode) {
      return div.notification.box(
        h3.title("Oder schreiben Sie uns direkt von hier!"),
        !visible
          ? button.button.isPrimary(
              {
                onclick: () => (visible = true)
              },
              "Jetzt schreiben!"
            )
          : [
              div.field(
                label.label("Name"),
                div.control(
                  input.input[
                    strValid(konkreteAnfrage.name) ? "is-success" : "is-danger"
                  ]({
                    type: "text",
                    placeholder: "Ihr Name",
                    oninput: m.withAttr(
                      "value",
                      v => (konkreteAnfrage.name = v)
                    ),
                    value: konkreteAnfrage.name
                  })
                ),
                !strValid(konkreteAnfrage.name)
                  ? p.help.isLink("Bitte geben Sie einen Namen ein!")
                  : null
              ),
              div.field(
                label.label("E-Mail"),
                div.control(
                  input.input[
                    emailValid(konkreteAnfrage.email)
                      ? "is-success"
                      : "is-danger"
                  ]({
                    type: "email",
                    placeholder: "Ihre E-Mail-Adresse",
                    oninput: m.withAttr(
                      "value",
                      v => (konkreteAnfrage.email = v)
                    ),
                    value: konkreteAnfrage.email
                  })
                ),
                !emailValid(konkreteAnfrage.email)
                  ? p.help.isLink("Bitte geben Sie eine E-Mail-Adresse ein!")
                  : null
              ),
              div.field(
                label.label("Ihre Nachricht"),
                div.control(
                  textarea.textarea[
                    strValid(konkreteAnfrage.message)
                      ? "is-success"
                      : "is-danger"
                  ]({
                    placeholder: "Ihre Nachricht an uns ...",
                    oninput: m.withAttr(
                      "value",
                      v => (konkreteAnfrage.message = v)
                    ),
                    value: konkreteAnfrage.message
                  })
                ),
                !strValid(konkreteAnfrage.message)
                  ? p.help.isLink("Bitte geben Sie eine Nachricht ein!")
                  : null
              ),
              div.field(
                div.control(
                  label.checkbox(
                    input({
                      type: "checkbox",
                      value: konkreteAnfrage.acc,
                      oninput: m.withAttr("value", v => {
                        console.log(v);
                        konkreteAnfrage.acc = v === "false";
                      })
                    }),
                    m.trust(`&nbsp;Ich bin einverstanden, dass mich das Vermessungsbüro Eismann
                                        kontaktiert und meine angegebenen Daten speichert und verarbeitet.
                                        Die Datenschutzhinweise (in neuem Fenster <a target="_blank" href="datenschutz.html">lesen</a>)
                                        habe ich gelesen und akzeptiere sie vollständig.`)
                  )
                )
              ),
              div.field.isGrouped(
                div.control(
                  button.button.isSuccess(
                    {
                      disabled: !konkreteAnfrage.acc,
                      onclick: () => sendMessage()
                    },
                    "Absenden"
                  )
                ),
                div.control(
                  button.button.isLight(
                    { onclick: () => (visible = false) },
                    "Abbrechen"
                  )
                )
              ),
              konkreteAntwort
                ? konkreteAntwort.error
                  ? div.notification.isSuccess(konkreteAntwort.message)
                  : div.notification.isDanger(konkreteAntwort.message)
                : null
              //  m("pre", JSON.stringify(konkreteAnfrage, null, 2))
              //  m("pre", JSON.stringify(konkreteAnfrage, null, 2))
              //  m("pre", JSON.stringify(konkreteAnfrage, null, 2))
            ]
      );
    }
  }

  if (false)
    m.mount(contact, {
      view(vnode) {
        return m(Form);
      }
    });
}
